import store from '@/store';
import { adClickReport } from '@/api/app';
import { Api } from '@/utils/index';
import { getLocalItem, getSessionItem } from '@/utils/longStorage';
import route from '@/router/index.js';

export const AdType = {
  ATP_Launch: 0, /// 启动页
  HOME_ADV: 2, //首页轮播图广告
  DETAILS_ADV: 3, ///播放详情页轮播图
  CHECKIN_ADV: 4, ///签到轮播图广告
  GAME_CENTER: 7, ///棋牌轮播图广告
  COLUMN_MORE: 10, // 专栏更多广告
  SHORT_VIDEO_LEFT_ADV: 16, ///短视频左上角广告
  MEMBER_CENTER_ADV: 20, ///会员中心广告
  HOME_BETWEEN: 13, // 首页专栏之间
  TOP_ADV_LIST: 301, // 九宫格横幅广告
};
export const MarqueeType = {
  community: 1, //社区
  movie: 2, //影视
};

/**
 * 获取广告
 * @param {*} type  广告类型
 * @returns   广告数据
 */
export function getAdItem(type) {
  let appConfig = store.state.app.appConfig;
  if (typeof appConfig === 'string') {
    appConfig = JSON.parse(store.state.app.appConfig);
  }
  if (type == undefined || !appConfig || !appConfig.adsInfoList || appConfig.adsInfoList.length == 0) return [];
  let advData = appConfig.adsInfoList.filter((item) => {
    if (item.position == type) {
      return item;
    }
  });
  if (!advData || advData.length == 0) return [];
  return advData;
}

/**
 * 获取模块id
 * @param subName
 * @returns {string|null|*}
 */
export function getModuleId(subName) {
  let moduleList = store.state.movies.moduleList;
  if (moduleList.length == 0) {
    return null;
  }
  let list = moduleList.filter((el) => el.subModuleName == subName);
  if (list.length > 0) {
    return list[0].id;
  }
  return '';
}

/**
 * 获取文字公告
 */
export function getAnnouncementText() {
  let appConfig = store.state.app.appConfig;
  if (typeof appConfig === 'string') {
    appConfig = JSON.parse(store.state.app.appConfig);
  }
  if (!appConfig || !appConfig.announList || appConfig.announList.length == 0) return [];
  let announList = appConfig.announList.filter((item) => {
    if (item.type == 0) {
      return item;
    }
  });
  if (!announList || announList.length == 0) return [];
  return announList;
}

// 获取app应用
export function getApplications() {
  let appConfig = store.state.app.appConfig;
  if (typeof appConfig === 'string') {
    appConfig = JSON.parse(store.state.app.appConfig);
  }
  if (!appConfig || !appConfig.officeConfigList || appConfig.officeConfigList.length == 0) return [];
  let officeConfigList = appConfig.officeConfigList.filter((item, index) => {
    if (index < 9) {
      return item;
    }
  });
  if (!officeConfigList || officeConfigList.length == 0) return [];
  return officeConfigList;
}

/**
 * 获取VIP特权
 */
export function getVipProductBenefits() {
  let appConfig = store.state.app.appConfig;
  if (typeof appConfig === 'string') {
    appConfig = JSON.parse(store.state.app.appConfig);
  }
  if (!appConfig || !appConfig.productBenefits || appConfig.productBenefits.length == 0) return [];
  return appConfig.productBenefits;
}

/**
 * 获取跑马灯
 */
export function getMarquee(type) {
  let list = store.state.app.marqueeList || [];
  if (list.length === 0) return '';
  let n = list.filter((e) => e.position == type);
  if (!n || n.length == 0) return '';
  var str = '';
  for (let i = 0; i < n.length; i++) {
    if (n.length > 1) {
      str += `${n[i].content}\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0`;
    } else {
      str += `${n[i].content}`;
    }
  }
  return str;
}

/**
 * 获取奖章
 */
export function getMedal(types) {
  // console.log(types)
  let newList = [];
  let list = store.getters.appConfig.userAwards || [];
  if (list.length === 0 || types?.length === 0) return newList;
  for (let i = 0; i < list.length; i++) {
    for (let j = 0; j < types?.length; j++) {
      if (types[j].number == list[i].number) {
        list[i].isExpire = types[j].isExpire;
        newList.push(list[i]);
      }
    }
  }
  // console.log(newList)
  return newList;
}

/**
 * 截取URL参数
 * @param name 需要截取url的参数
 * @returns
 */
function getUrlParam(url, name) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)'); //构造一个含有目标参数的正则表达式对象
  var r = url.substr(1).match(reg); //匹配目标参数

  if (r != null) return unescape(r[2]);
  return null; //返回参数值
}

/**
 * 活动跳转
 * @param {*} item
 */
function Activity(url) {
  let murl = 'https://' + url;
  if (murl.indexOf('token') != -1) {
    let token = getLocalItem('token') || '';
    murl = murl.replace(/token=/, `token=${token}`);
  }
  if (murl.indexOf('apihost') != -1) {
    let baseUrl = getSessionItem('baseUrl').replace(/\/api\/h5\//, '');
    murl = murl.replace(/apihost=/, `apihost=${baseUrl}`);
  }
  location = murl;
}

/**
 * 内部跳转
 * @param {*} url  内部跳转的url
 */
async function innerJump(url) {
  let urlIndex = url.indexOf('?');
  let uri = url;
  if (urlIndex != -1) {
    uri = url.substring(0, urlIndex);
  }
  const routeMap = {
    checkIn: '/checkIn', //签到
    sharepromote: '/sharePromote', //分享推广
    membercentre: '/memberCentre', //会员中心页面
    kefu: '/kefu', //在线客服页面
    gamePage: '/game', //游戏页面
    incomeCenter: '/incomeCenter', //收益中心
    taskHall: '/taskHall', //任务中心
    announcement: '/announcement', //公告主界面
    gameWallet: '/myWallet', //游戏钱包
  };
  // 跳转博主
  if (uri == 'userHomePage') {
    let uid = getUrlParam(url.substring(urlIndex), 'uid');
    if (!uid) return;
    route.push({
      path: '/userHomePage',
      query: {
        id: uid,
      },
    });
    return;
  }
  // console.log(uri, '---------')
  // console.log(url.substring(urlIndex))
  // 跳转视频详情
  if (uri == 'horizontalvideo') {
    let id = getUrlParam(url.substring(urlIndex), 'videoId');
    // console.log(id)
    if (!id) return;
    route.push({
      path: '/horizontalVideo',
      query: {
        id: id,
      },
    });
    return;
  }
  let routePath = routeMap[uri];

  // console.log(routeMap)
  // console.log(uri)
  if (routePath) {
    route.push(routePath);
    return;
  }
  // 跳转活动详情页
  if (uri == 'taskDetails') {
    let id = getUrlParam(url.substring(urlIndex), 'id');
    if (!id) return;
    route.push({
      path: '/activityDetails',
      query: {
        id: id,
      },
    });
  }
}

// 转base64
function encodeBase64(str) {
  let base64Str = Buffer.from(str, 'utf-8').toString('base64');
  return base64Str;
}

/**
 * 跳转广告
 * @param {*} item 广告信息
 */
export async function jumpAdv(item) {
  let id = item.id;
  let name = item.name;
  try {
    if (id) {
      Api(adClickReport, {
        id: id,
        adName: name,
      });
    }
  } catch (e) {
    console.log(e);
  }
  let url = item.jumpLink || item.jumpTo || item.href || item.link || item.jump || item.officialUrl;
  let index = url.indexOf('yinseinner');
  let yinssIndex = url.indexOf('yinselinks');
  if (index != -1) {
    innerJump(url.substring(13));
    return;
  }
  if (yinssIndex != -1) {
    Activity(url.substring(13));
    return;
  }
  setTimeout(() => {
    window.open(url);
  });
  // location = url
}
