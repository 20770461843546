<template>
  <van-tabbar v-model="active" active-color="#e9e9e9" inactive-color="#727272" class="tabbar-warp" :border="false" :safe-area-inset-bottom="true">
    <van-tabbar-item @click="onHome" name="Home">
      <span>首页</span>
      <template #icon="props">
        <img :src="props.active ? homeIconActive : homeIcon" />
      </template>
    </van-tabbar-item>
    <van-tabbar-item to="/activity" name="Activity">
      <span>活动</span>
      <template #icon="props">
        <img :src="props.active ? activityIconActive : activityIcon" />
      </template>
    </van-tabbar-item>
    <van-tabbar-item to="/game" name="Game">
      <span>棋牌</span>
      <template #icon="props">
        <img :src="props.active ? gameIconActive : gameIcon" />
      </template>
    </van-tabbar-item>
    <van-tabbar-item to="/shortVideo" name="Shortvideo">
      <span>短视频</span>
      <template #icon="props">
        <img :src="props.active ? shortVideoIconActive : shortVideoIcon" />
      </template>
    </van-tabbar-item>
    <van-tabbar-item to="/mine" name="Mine">
      <span>我的</span>
      <template #icon="props">
        <img :src="props.active ? mineIconActive : mineIcon" />
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>
<script>
import { mapGetters } from 'vuex';
import { Tabbar, TabbarItem } from 'vant';
import { firstUpperCase } from '@/utils';
import { getSessionItem } from '@/utils/longStorage';

export default {
  name: 'ShopCats',
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
  },
  computed: {
    ...mapGetters({
      unreadMessages: 'unreadMessages',
      appConfig: 'appConfig',
    }),
  },
  data() {
    return {
      active: '/home',
      homeIcon: require('@/assets/png/homeIcon.png'),
      homeIconActive: require('@/assets/gif/homeIconActive.gif'),
      activityIcon: require('@/assets/png/activityIcon.png'),
      activityIconActive: require('@/assets/gif/activityIconActive.gif'),
      gameIcon: require('@/assets/png/gameIcon.png'),
      gameIconActive: require('@/assets/gif/gameIconActive.gif'),
      shortVideoIcon: require('@/assets/png/shortVideoIcon.png'),
      shortVideoIconActive: require('@/assets/gif/shortVideoIconActive.gif'),
      mineIcon: require('@/assets/png/mineIcon.png'),
      mineIconActive: require('@/assets/gif/mineIconActive.gif'),
    };
  },
  mounted() {
    this.refreshRoute(this.$route.path);
  },
  created() {
    this.refreshRoute(this.$route.path);
  },
  methods: {
    // 刷新路由
    refreshRoute(path) {
      if (path.match(/\/(\S*)\//)) {
        this.active = firstUpperCase(path.match(/\/(\S*)\//)[1]);
      } else {
        this.active = firstUpperCase(this.$route.name);
      }
    },
    onHome() {
      this.active = '/home';
      let path = getSessionItem('homePath') || '/home';
      this.$router.replace(path);
    },
    refreshPage() {
      this.$store.commit('commnity/CHANGE_REFRESHSTA', true);
    },
  },
  watch: {
    '$route.path'(newVal, oldVal) {
      this.refreshRoute(newVal);
    },
  },
};
</script>
<style scoped lang="scss">
/deep/ .van-tabbar-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
/deep/ .van-tabbar-item__text {
  margin-top: 24px;
  font-size: 11px;
  color: rgb(65, 65, 65);
}
/deep/ .van-tabbar-item__icon {
  position: unset;
  margin-bottom: unset;
}
/deep/ .van-tabbar-item__icon img {
  height: 56px;
  width: 56px;
  position: absolute;
  left: 50%;
  top: -22px;
  transform: translate(-50%, 0);
}
</style>
