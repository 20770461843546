<template>
  <div class="share_warp">
    <van-overlay :show="shareDate.show" @click="shareDate.show = false">
      <div>
        <div class="share_box">
          <div class="appBox">
            <div class="appLogo"></div>
            <div class="appInfo">
              <div class="appName">粉柚</div>
              <div class="appDesc">一款边吃瓜边赚钱的APP</div>
            </div>
          </div>

          <div class="activity">
            每邀请
            <span class="cff678f">1人</span>
            送
            <span class="cff678f">1天</span>
            会员
            <br />
            被邀请充值 最高返利
            <span class="cff678f">76%</span>
          </div>
          <div :class="['top_info', { shu_info: shareDate.info.isVerScreen }]">
            <ImgDecypt :src="shareDate.info.cover" class="cover shu" />
          </div>
          <div :class="['content_info', { mab0: !shareDate.info.isVerScreen }]">
            <div :class="['user_info', { mt24: !shareDate.info.isVerScreen }]">
              <div class="user">
                <h2 class="ellipsis">{{ shareDate.info.isTag ? '#' : '@' }}{{ shareDate.info.publisher.name || '未知' }}</h2>
                <p>{{ shareDate.info.title }}</p>
              </div>
              <div class="qrcode">
                <div id="qrcode"></div>
              </div>
            </div>
          </div>
        </div>
        <div :class="['bottom_info']">
          <div class="btn" @click.stop="share">截图保存分享</div>
          <div class="btn copyUrl" @click.stop="copyUrl">复制分享链接</div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { Overlay, Toast } from 'vant';
import { mapGetters } from 'vuex';
import ImgDecypt from '@/components/ImgDecypt';
import QRCode from 'qrcodejs2';

export default {
  computed: {
    ...mapGetters({
      shareDate: 'shareDate',
      userInfo: 'userInfo',
    }),
  },
  components: {
    [Overlay.name]: Overlay,
    ImgDecypt,
  },
  created() {},
  mounted() {
    this.generateCode(this.userInfo.promoteURL);
  },
  methods: {
    // 生成二维码
    generateCode(url) {
      this.$nextTick(() => {
        let qrcode = document.getElementById('qrcode');
        new QRCode(qrcode, {
          text: url,
          width: 81,
          height: 81,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.L,
        });
      });
    },
    share() {
      Toast('请手动截图分享');
    },
    copyUrl() {
      this.$copyText(this.userInfo.promoteURL).then(
        (e) => {
          Toast('复制成功');
        },
        (e) => {
          Toast('复制失败');
        },
      );
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .van-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2002;
}

.share_warp {
  .bottom_info {
    display: flex;
    width: 100%;
    justify-content: space-around;
    padding-bottom: 10px;
    padding-top: 10px;

    .btn {
      width: 140px;
      height: 38px;
      color: #ff678f;
      background: #ffffff;
      font-size: 16px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .copyUrl {
      background: #ff678f;
      color: #ffffff;
    }
  }
  .share_box {
    width: 301px;
    background: url('../../assets/png/share_bg.png') no-repeat;
    background-size: 100% 100%;
    text-align: center;
    position: relative;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    position: relative;

    .appBox {
      padding: 25px 20px 12px;
      display: flex;
      align-items: center;
      .appLogo {
        width: 50px;
        height: 50px;
        background: url('../../assets/png/appLogo.png') no-repeat;
        background-size: 100% 100%;
        margin-right: 10px;
      }
      .appInfo {
        color: rgb(51, 51, 51);
        text-align: left;
        .appName {
          font-size: 18px;
        }
        .appDesc {
          font-size: 16px;
        }
      }
    }

    .activity {
      color: rgb(21, 21, 21);
      padding: 0 20px 0;
      font-size: 15px;
      text-align: left;
      color: #333333;
    }

    .cff678f {
      color: #ff678f;
    }
    .top_info {
      padding-top: 28px;
      margin: 0 auto;
      .cover {
        width: 260px;
        height: 140px;
        border-radius: 8px;
        margin: 0 auto;
        overflow: hidden;
        // 兼容ios的圆角问题
        transform: translateZ(0px);

        /deep/ .img-decypt {
          background: transparent !important;
        }
        /deep/ .vanImage {
          background: transparent !important;
        }
      }
    }

    .shu_info {
      padding-top: 32px;
      margin: 0 auto;

      .shu {
        width: 158px;
        height: 180px;
      }
    }

    .content_info {
      padding-top: 20px;
      color: #000000;
      margin-bottom: 4px;
      .user_info {
        display: flex;
        padding: 0 18px 10px;

        .user {
          text-align: left;
          padding-left: 5px;

          h2 {
            font-size: 16px;
            padding-right: 10px;
            width: 160px;
            padding-bottom: 5px;
          }

          p {
            color: #666666;
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
        }

        .qrcode {
          width: 81px;
          height: 81px;
          margin-left: 10px;

          /deep/ img {
            width: 81px;
            height: 81px;
          }
        }
      }
    }

    > svg {
      font-size: 120px;
    }
  }

  .close {
    position: absolute;
    font-size: 32px;
    bottom: -60px;
    left: 50%;
    margin-left: -16px;
  }
}
.pt15 {
  padding-top: 15px !important;
}
.mab0 {
  margin-bottom: 0 !important;
}
.mt24 {
  margin-top: 24px !important;
}
</style>
