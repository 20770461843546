import router from './router';
import store from './store';
import { getSessionItem } from '@/utils/longStorage';
let lastVid = '';
let lastUid = '';
let lastTagid = '';
//全局路由导航
router.beforeEach((to, from, next) => {
  const appConfigStr = getSessionItem('appConfig');
  const baseUrl = getSessionItem('baseUrl');
  if ((!appConfigStr || appConfigStr.length == 0 || !baseUrl || baseUrl.length == 0) && to.path != '/') {
    router.push({
      path: '/',
    });
    return;
  }
  //缓存组件
  let keepAliveMap = new Map();
  keepAliveMap.set('movies', 'movies');
  keepAliveMap.set('Main', 'Main');
  keepAliveMap.set('home', 'home');
  keepAliveMap.set('search', 'search');
  keepAliveMap.set('announcement', 'announcement');
  keepAliveMap.set('tags', 'tags');
  keepAliveMap.set('HistoryRecord', 'HistoryRecord');
  keepAliveMap.set('myCollection', 'myCollection');
  keepAliveMap.set('communityVideo', 'communityVideo');
  keepAliveMap.set('memberCentre', 'memberCentre');
  keepAliveMap.set('tagVideo', 'tagVideo');
  keepAliveMap.set('topicMore', 'topicMore');
  keepAliveMap.set('searchDetail', 'searchDetail');
  keepAliveMap.set('game', 'game');
  //搜索页面
  if (
    from.path == '/search' &&
    to.path != '/search/details/movies' &&
    to.path != '/search/details/video' &&
    to.path != '/search/details/graphices' &&
    to.path != '/search/details/user' &&
    to.path != '/search/details/topic' &&
    to.path != '/communityVideo' &&
    to.path != '/userHomePage' &&
    to.path != '/horizontalVideo' &&
    to.path != '/shortVideoDetails' &&
    to.path != '/topicMore'
  ) {
    keepAliveMap.delete('search');
  }
  //标签详情页
  if (
    (from.path === '/tags/video' || from.path === '/tags/graphics' || '/tags/movies') &&
    to.path != '/tags/video' &&
    to.path != '/tags/graphics' &&
    to.path != '/tags/movies' &&
    to.path != '/communityVideo' &&
    to.path != '/picturePreview' &&
    to.path != '/horizontalVideo' &&
    to.path != '/userHomePage'
  ) {
    keepAliveMap.delete('tags');
  }
  //标签页跳标签页
  if (
    (from.path == '/tags/video' || from.path == '/tags/graphics') &&
    (to.query.fromPath == '/tags/graphics' || to.query.fromPath == '/tags/video') &&
    from.path != '/communityVideo' &&
    from.path != '/picturePreview'
  ) {
    keepAliveMap.delete('tags');
  }
  if ((to.path == '/tags/video' || to.path == '/tags/movies') && from.path == '/horizontalVideo' && !from.query.fromPath) {
    keepAliveMap.delete('tags');
  }
  //标签页跳播放页调标签页
  if ((to.path == '/tags/video' || to.path == '/tags/movies') && from.query.fromPath == 'tags') {
    let tagId = getSessionItem('tagId');
    if (tagId) {
      keepAliveMap.delete('tags');
    }
  }
  if ((to.path == '/tags/video' || to.path == '/tags/movies' || to.path == '/tags/graphics') && from.query.fromRoutePath != 'TagsVideo') {
    if (lastTagid != to.query.id && lastTagid != '') {
      keepAliveMap.delete('tags');
    }
    lastTagid = to.query.id;
  }
  //公告详情
  if (from.path == '/announcement' && to.path != '/announDetails') {
    keepAliveMap.delete('announcement');
  }
  //历史详情
  if (from.path == '/historyMovies' && to.path == '/mine') {
    keepAliveMap.delete('HistoryRecord');
    console.log(12313223);
  }
  if (from.path == '/historyVideo' && to.path == '/mine') {
    keepAliveMap.delete('HistoryRecord');
  }
  if (from.path == '/HistoryPost' && to.path == '/mine') {
    keepAliveMap.delete('HistoryRecord');
  }
  if ((to.path == '/horizontalVideo' || to.path == '/shortVideo' || to.path == '/shortVideoDetails') && !to.query.fromTagVideo) {
    keepAliveMap.delete('tagVideo');
  }
  // 我的收藏
  // if (to.path == '/mine') {
  //   keepAliveMap.delete('myCollection');
  // }
  // 首页更多
  if (to.path == '/home' && from.path == '/topicMore') {
    keepAliveMap.delete('topicMore');
  }
  // 首页更多
  if (to.path == '/search' && from.path == '/search/detail') {
    keepAliveMap.delete('searchDetail');
  }
  //会员中心
  if (from.path == '/memberCentre' && to.path != '/mine') {
    keepAliveMap.delete('memberCentre');
  }
  if (from.path == '/myCollection' && to.path == '/mine') {
    keepAliveMap.delete('myCollection');
  }
  //视频详情
  if (
    from.path == '/communityVideo' &&
    to.path != '/userHomePage' &&
    to.path != '/myWallet' &&
    to.path != '/memberCentre' &&
    to.path != '/tags/video'
  ) {
    keepAliveMap.delete('communityVideo');
  }
  if (to.path == '/communityVideo') {
    if (lastVid != to.query.vid && lastVid != '') {
      keepAliveMap.delete('communityVideo');
    }
    lastVid = to.query.vid;
  }
  let keepAliveList = [...keepAliveMap.values()];
  store.commit('app/SET_KEEP', keepAliveList);

  if (to.matched.length === 0) {
    //如果未能匹配到路由 则跳转到首页
    next('/');
  } else {
    next();
  }
});
