import Vue from 'vue';
import VueRouter from 'vue-router';

const includPush = VueRouter.prototype.push;
const includReplace = VueRouter.prototype.replace;
VueRouter.prototype.push = function push(location) {
  return includPush.call(this, location).catch((err) => err);
};
VueRouter.prototype.replace = function replace(location) {
  return includReplace.call(this, location).catch((err) => err);
};
import Main from '@/views/Main';
import Splash from '@/views/splash/index'; //启动页

/*********************** 首页 ******************/
const Home = () => import('@/views/home/index'); // 首页
const TopicMore = () => import('@/views/home/topicMore/index'); // 专题更多
const Search = () => import('@/views/home/search/index'); // 搜索
const SearchDetail = () => import('@/views/home/search/detail'); // 搜索

/*********************** 游戏 ******************/
const Game = () => import('@/views/game'); //游戏大厅
const GamePlay = () => import('@/views/game/gamePlay'); //游戏页面

/*********************** 活动 ******************/
const Activity = () => import('@/views/activity'); //活动
const ActivityDetails = () => import('@/views/activity/activityDetails'); //活动详情
const CheckIn = () => import('@/views/mine/checkIn'); //签到

/*********************** 约炮 ******************/
const YuePao = () => import('@/views/yuepao'); //约炮模块

/*********************** 影视 ******************/
const HorizontalVideo = () => import('@/views/movies/horizontalVideo'); //横版视频详情

/*********************** 视频 ******************/
const ShortVideo = () => import('@/views/shortVideo'); //视频
const TagVideo = () => import('@/views/shortVideo/tagVideo'); //标签视频
const ShortVideoDetails = () => import('@/views/shortVideo/shortVideoDetails.vue'); //短视频详情

/*********************** 我的  ******************/
const Mine = () => import('@/views/mine'); //我的
const PersonalInfo = () => import('@/views/mine/personalInfo'); //个人资料
const NickName = () => import('@/views/mine/personalInfo/nickName'); //昵称编辑（待定）
const BindPhone = () => import('@/views/mine/personalInfo/bindPhone'); //绑定手机号
const PhonePage = () => import('@/views/mine/personalInfo/phonePage'); //手机号
const BindSuccess = () => import('@/views/mine/personalInfo/bindSuccess'); //绑定成功
const BindInvitationCode = () => import('@/views/mine/personalInfo/bindInvitationCode'); //绑定邀请码
const ChangeAvatar = () => import('@/views/mine/changeAvatar'); //切换头像
const ActCredentials = () => import('@/views/mine/personalInfo/actCredentials'); //账号凭证
const ActRecovery = () => import('@/views/mine/personalInfo/actRecovery'); //账号找回
const PhoneRecovery = () => import('@/views/mine/personalInfo/phoneRecovery'); //手机号找回
const MemberCentre = () => import('@/views/mine/memberCentre'); //会员中心
const MyWallet = () => import('@/views/mine/myWallet'); //我的钱包
const ExchangeMember = () => import('@/views/mine/exchangeMember'); //兑换会员
const Withdraw = () => import('@/views/mine/withdraw'); //提现
const WithdrawDetails = () => import('@/views/mine/withdrawDetails'); //提现明细
const MyCollection = () => import('@/views/mine/myCollection'); //我的收藏
// const CollectionMovieList = () => import('@/views/mine/myCollection/collecMovieList'); //我的收藏-影视
const BillDetails = () => import('@/views/mine/billDetails'); //账单明细
const PromoRecord = () => import('@/views/mine/promoRecord'); //推广记录
const SharePromote = () => import('@/views/mine/sharePromote'); //分享推广
const HistoryRecord = () => import('@/views/mine/historyRecord'); //历史记录
const HistoryVideo = () => import('@/views/mine/historyRecord/historyVideo'); //历史记录 视频
const HistoryMovies = () => import('@/views/mine/historyRecord/historyMovies'); //历史记录 影视
const OfficialCommunity = () => import('@/views/mine/officialCommunity'); //官方社群
const BoutiqueApp = () => import('@/views/mine/boutiqueApp'); //精品应用
const Kefu = () => import('@/views/kefu'); //客服
const FlowDetails = () => import('@/views/mine/flowDetails'); // 流水明细
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Splash',
    component: Splash,
  },
  {
    path: '/search',
    name: 'Search',
    component: Search,
  },
  {
    path: '/search/detail',
    name: 'SearchDetail',
    component: SearchDetail,
  },
  {
    path: '/gamePlay',
    name: 'gamePlay',
    component: GamePlay,
  },
  {
    path: '/withdrawDetails',
    name: 'withdrawDetails',
    component: WithdrawDetails,
  },
  {
    path: '/topicMore',
    name: 'TopicMore',
    component: TopicMore,
  },
  {
    path: '/horizontalVideo',
    name: 'HorizontalVideo',
    component: HorizontalVideo,
  },
  {
    path: '/personalInfo',
    name: 'PersonalInfo',
    component: PersonalInfo,
  },
  {
    path: '/nickName',
    name: 'NickName',
    component: NickName,
  },
  {
    path: '/bindPhone',
    name: 'BindPhone',
    component: BindPhone,
  },
  {
    path: '/phonePage',
    name: 'PhonePage',
    component: PhonePage,
  },
  {
    path: '/bindSuccess',
    name: 'BindSuccess',
    component: BindSuccess,
  },
  {
    path: '/bindInvitationCode',
    name: 'BindInvitationCode',
    component: BindInvitationCode,
  },
  {
    path: '/changeAvatar',
    name: 'ChangeAvatar',
    component: ChangeAvatar,
  },
  {
    path: '/actCredentials',
    name: 'ActCredentials',
    component: ActCredentials,
  },
  {
    path: '/actRecovery',
    name: 'ActRecovery',
    component: ActRecovery,
  },
  {
    path: '/phoneRecovery',
    name: 'PhoneRecovery',
    component: PhoneRecovery,
  },
  {
    path: '/memberCentre',
    name: 'MemberCentre',
    component: MemberCentre,
  },
  {
    path: '/myWallet',
    name: 'MyWallet',
    component: MyWallet,
  },
  {
    path: '/exchangeMember',
    name: 'ExchangeMember',
    component: ExchangeMember,
  },
  {
    path: '/withdraw',
    name: 'Withdraw',
    component: Withdraw,
  },
  {
    path: '/billDetails',
    name: 'BillDetails',
    component: BillDetails,
  },
  {
    path: '/flowDetails',
    name: 'flowDetails',
    component: FlowDetails,
  },
  {
    path: '/promoRecord',
    name: 'PromoRecord',
    component: PromoRecord,
  },
  {
    path: '/sharePromote',
    name: 'SharePromote',
    component: SharePromote,
  },
  {
    path: '/historyRecord',
    name: 'HistoryRecord',
    component: HistoryRecord,
    redirect: '/historyMovies',
    children: [
      {
        path: '/historyVideo',
        name: 'HistoryVideo',
        component: HistoryVideo,
      },
      {
        path: '/historyMovies',
        name: 'HistoryMovies',
        component: HistoryMovies,
      },
    ],
  },
  {
    path: '/officialCommunity',
    name: 'OfficialCommunity',
    component: OfficialCommunity,
  },
  {
    path: '/boutiqueApp',
    name: 'BoutiqueApp',
    component: BoutiqueApp,
  },
  {
    path: '/kefu',
    name: 'Kefu',
    component: Kefu,
  },
  {
    path: '/activityDetails',
    name: 'activityDetails',
    component: ActivityDetails,
  },
  {
    path: '/checkIn',
    name: 'checkIn',
    component: CheckIn,
  },
  {
    path: '/tagVideo',
    name: 'tagVideo',
    component: TagVideo,
  },
  {
    path: '/shortVideoDetails',
    name: 'shortVideoDetails',
    component: ShortVideoDetails,
  },
  {
    path: '/myCollection',
    name: 'MyCollection',
    component: MyCollection,
  },
  {
    path: '/main',
    name: 'Main',
    component: Main,
    children: [
      {
        path: '/home',
        name: 'Home',
        component: Home,
      },
      {
        path: '/shortVideo',
        name: 'ShortVideo',
        component: ShortVideo,
      },
      {
        path: '/game',
        name: 'Game',
        component: Game,
      },
      {
        path: '/mine',
        name: 'Mine',
        component: Mine,
      },
      {
        path: '/yuepao',
        name: 'yuepao',
        component: YuePao,
      },
      {
        path: '/activity',
        name: 'activity',
        component: Activity,
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
