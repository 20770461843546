<template>
  <div class="splash">
    <div class="countdown" v-if="isStateTime" @click="goApp">
      <span v-if="countdown !== 0">{{ countdown }}</span>
      <svg-icon v-else iconClass="close" class="close"></svg-icon>
    </div>
    <transition name="shareShow">
      <LazyImg
        :src="ATP_LaunchList[0].cover"
        :isSplash="true"
        v-if="ATP_LaunchList.length > 0"
        @click.native="goAdv(ATP_LaunchList[0])"
        @imgLoad="imgload"
        @error="imgload"
      />
    </transition>
    <SystemHint :show="pingShow" />
    <UpgradePopup :show="isUpgrade" />
    <div class="selectLineBox" v-if="loading">
      <van-loading color="#1989fa" size="30" />
      <div class="selectLine">选线中...</div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { queryApi } from '@/api/app';
import store from '@/store';
import { cleanAllSession, getLocalItem, setLocalItem, setSessionItem, removeLocal } from '@/utils/longStorage';
import { AdType, getAdItem, jumpAdv } from '@/utils/getConfig';
import { mapGetters } from 'vuex';
import { getDevType } from '@/utils';
import { userLogin } from '@/api/user';
import { Dialog } from 'vant';

export default {
  name: 'Splash',
  components: {
    LazyImg: () => import('@/components/LazyImg/index'),
    SystemHint: () => import('@/components/SystemHint/index'),
    UpgradePopup: () => import('@/components/UpgradePopup/index'),
  },
  data() {
    return {
      timer: null,
      countdown: 3, //广告倒计时
      retryNum: 3, //获取token失败重试次数
      ATP_LaunchList: [], //启动页广告
      isStateTime: false, //是否开始显示倒计时
      checkTimer: null, //检测图片是否加载定时器
      checkTime: 3,
      pingShow: false, // 选线失败窗口
      isUpgrade: true, //是否升级
      checkLineErrorNum: 0, //选线失败次数
      isResLock: false, // 请求结果锁
      resResult: undefined, // 响应结果
      pingCheckCount: 0, // 请求的ping失败次数
      loading: false, // 选线loading
    };
  },
  computed: {
    ...mapGetters({
      UID: 'UID',
    }),
  },
  created() {
    // this.init();
  },
  methods: {
    imgload() {
      if (!this.timer) {
        this.adControl();
        if (this.checkTimer) {
          clearInterval(this.checkTimer);
          this.checkTimer = null;
        }
      }
    },
    goAdv(item) {
      jumpAdv(item);
    },
    //初始化数据
    init() {
      const hosts = JSON.parse(process.env.VUE_APP_BASE_HOSTS);
      this.resResult = new Array(hosts.length);
      hosts.forEach((url, index) => {
        axios({
          method: 'GET',
          url: url + process.env.VUE_APP_BASE_PATH + 'ping/check',
          timeout: 1000 * 10, // 10秒超时
        })
          .then(async (res) => {
            if (res.data.code == 200) {
              if (!this.isResLock) {
                this.isResLock = true;
                this.resResult[index] = true;
                let baseUrl = url + process.env.VUE_APP_BASE_PATH;
                setSessionItem('baseUrl', baseUrl);
                store.commit('app/SET_BASEURL', baseUrl);
                //获取用户信息
                let isUserOk = await this.getUserInfo();
                if (!isUserOk) {
                  Dialog.alert({
                    title: '提示',
                    message: '获取用户信息失败',
                    confirmButtonText: '重试',
                    theme: 'round-button',
                  }).then(() => {
                    cleanAllSession();
                    removeLocal('token');
                    this.init();
                  });
                  return;
                }
                //获取配置信息
                let isConfigOk = await this.getConfigData();
                if (!isConfigOk) {
                  Dialog.alert({
                    title: '提示',
                    message: '获取全局配置信息失败',
                    confirmButtonText: '重试',
                    theme: 'round-button',
                  }).then(() => {
                    cleanAllSession();
                    this.init();
                  });
                  return;
                }
                this.ATP_LaunchList = getAdItem(AdType.ATP_Launch);
                if (!this.ATP_LaunchList.length) {
                  this.isStateTime = true;
                  this.adControl();
                } else {
                  //检测图片是否3秒内加载
                  this.checkImgLoad();
                }
                this.$store.dispatch('app/getMarquee');
                this.$store.dispatch('user/getVipTiroCountdown');
              }
            } else {
              this.pingErrorPop(hosts);
            }
          })
          .catch((e) => {
            this.pingErrorPop(hosts);
          });
      });
    },
    pingErrorPop(hosts) {
      // 只有最后一条域名出错时，判断响应结果不存在true的时候，说明全部不通，即弹出选线失败
      this.pingCheckCount++;
      if (this.pingCheckCount == hosts.length) {
        this.pingCheckCount = 0;
        let index = this.resResult.indexOf(true);
        if (index == -1) {
          if (this.checkLineErrorNum == 1) {
            this.pingShow = true;
            return;
          }
          Dialog.alert({
            title: '提示',
            message: '选线失败',
            confirmButtonText: '重试',
            theme: 'round-button',
          }).then(() => {
            this.checkLineErrorNum++;
            cleanAllSession();
            this.init();
          });
        }
      }
    },
    checkImgLoad() {
      this.checkTimer = setInterval(() => {
        if (this.checkTime <= 0) {
          clearInterval(this.checkTimer);
          this.checkTimer = null;
          if (!this.timer) {
            this.adControl();
          }
          return;
        }
        this.checkTime--;
      }, 1000);
    },
    //开机广告
    adControl() {
      this.countdownStrat();
      this.isStateTime = true;
    },
    //启动倒计时
    countdownStrat() {
      this.loading = false;
      this.timer = setInterval(() => {
        if (this.countdown <= 0) {
          clearInterval(this.timer);
        } else {
          this.countdown--;
        }
      }, 1000);
    },
    //获取全局配置
    async getConfigData() {
      let ret = await this.$Api(queryApi);
      if (ret.code === 200) {
        const appConfig = ret.data;
        appConfig.sourceList.forEach((i) => {
          if (i.type == 'DataBuried') {
            sessionStorage.setItem('logApi', i.domain[0].url);
          }
        });
        setSessionItem('appConfig', JSON.stringify(appConfig));
        store.commit('app/SET_CONFIG_DATA', appConfig);
        return true;
      }
      return false;
    },
    async getUserInfo() {
      const token = getLocalItem('token');
      if (token) {
        this.$store.dispatch('user/getUserInfo');
        return true;
      }
      if (!this.UID) {
        this.$store.dispatch('app/GET_UID');
      }
      const req = {
        devID: this.UID,
        sysType: getDevType(),
        cutInfos: Object.keys(this.$route.query).length > 0 ? JSON.stringify(this.$route.query) : undefined,
      };
      try {
        let res = await this.$Api(userLogin, req);
        if (res.code === 200) {
          this.$store.commit('user/SET_USERINFO', res.data);
          setSessionItem('userInfo', JSON.stringify(res.data));
          setLocalItem('token', res.data.token);
          return true;
        }
        //如果不等于 200 进行重试
        this.retryNum--;
        if (!token && this.retryNum >= 0) {
          await this.getUserInfo();
        }
        //如果不等于 200 进行重试
      } catch (e) {
        this.retryNum--;
        if (!token && this.retryNum >= 0) {
          await this.getUserInfo();
        }
      }
    },
    goApp() {
      if (this.countdown != 0) return;
      // this.$router.replace({ path: '/movies/softGirl' });
      // this.$router.replace({ path: '/community/recommend' });
      this.$router.replace({ path: '/home' });
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
};
</script>
<style lang="scss" scoped>
.splash {
  width: 100%;
  height: 100%;
  background: url('../../assets/png/ad.webp') center no-repeat;
  background-size: 100% 100%;
  object-fit: contain;
  position: relative;
  // /deep/ .vanImage,
  // /deep/ .van-image__error,
  // /deep/ .splash-error-img {
  //   background: transparent !important;
  // }
  .countdown {
    position: absolute;
    top: 30px;
    right: 25px;
    z-index: 999;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 24px;
    box-shadow: rgba(0, 0, 0, 0.3) 1px 1px 1px;
  }

  .close {
    font-size: 30px;
  }
  .selectLineBox {
    height: 120px;
    width: 150px;
    color: white;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    position: fixed;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .selectLine {
      margin-top: 15px;
      font-size: 16px;
    }
  }
}
</style>
