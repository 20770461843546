<template>
  <div class="animationImg">
    <transition name="bounceFade">
      <img v-show="isLoad" :src="decyImg" @load="load" alt="加载失败" :class="{ round: round }" @click="toLink(itemData)" />
    </transition>
    <transition name="fade">
      <div class="loading" v-if="!isLoad" :class="{ round: round }">
        <!-- <svg-icon icon-class="place" /> -->
        <!-- <div class="place"></div> -->
        <img :src="place" class="place" alt="">
      </div>
    </transition>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { imgDecyptApi } from '@/api/app';
import { jumpAdv } from '@/utils/getConfig';

export default {
  props: {
    itemData: {
      type: Object,
    },
    src: {
      type: String,
      required: true,
      default() {
        return '';
      },
    },
    isNeedDecypt: {
      //是否需要解密
      type: Boolean,
      required: false,
      default() {
        return true;
      },
    },
    round: {
      //图片是否显示为圆形
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
  },

  data() {
    return {
      decyImg: '',
      isLoad: false,
      place: require('../../assets/png/loading_bg.png'),
    };
  },
  computed: {
    ...mapGetters({
      imgApi: 'imgApi',
    }),
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (!this.isNeedDecypt) {
        if (this.itemData != null) {
          this.decyImg = this.itemData['cover'];
        } else {
          this.decyImg = this.src;
        }
      } else {
        let src = null;
        if (this.itemData != null) {
          src = this.itemData['cover'];
        } else {
          src = this.src;
        }
        try {
          let imgApi = this.imgApi;
          if (imgApi.slice(-1) != '/') {
            imgApi = imgApi + '/';
          }
          if (!imgApi || !src) return;
          imgDecyptApi(imgApi + src)
            .then((url) => {
              this.decyImg = url;
            })
            .catch((err) => {
              console.log(err);
              this.$emit('error');
            });
        } catch (error) {
          console.log(error);
          this.$emit('error');
        }
      }
    },
    load() {
      this.isLoad = true;
      this.$emit('load');
    },
    toLink(item) {
      if (item != null) {
        jumpAdv(item);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.bounceFade-enter-active {
  animation: bounceFade-in 0.4s;
}
.bounceFade-leave-active {
  animation: bounceFade-in 0.4s;
}

@keyframes bounceFade-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.round {
  border-radius: 50% !important;
}

.animationImg {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover !important;
  }
}
.loading {
  width: 100%;
  height: 100%;
  // background: #e6e7e9;
  background-image: linear-gradient(rgba(247, 74, 130, 0.12), rgba(252, 169, 132, 0.12));
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 50% !important;
    width: auto !important;
  }
  .place {
    height: 50% !important;
    width: auto !important;
    object-fit: fill;
    // background: url('./../../assets/png/loading_bg.png') no-repeat;
    // background-size: 100% 100%;
  }
}
</style>
