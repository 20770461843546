import axios from '@/utils/request';
import { getSessionItem } from '@/utils/longStorage';
import imgAxios from 'axios';
// 获取域名地址
export function queryApi(data) {
  return axios.get(`ping/domain/h5`, {
    params: data,
  });
}

// 获取城市列表
export function getCityList(data) {
  return axios.get(`loufeng/cities`, {
    params: data,
  });
}

// 上传图片
export function uploadImg(data) {
  return axios({
    url: '/vid/uploadStatic',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  });
}

// 分片上传视频
export function upLoadVideo(data, processCallback) {
  return axios.post('vid/uploadDotJson', data, {
    headers: {
      'Content-Type': 'application/json',
    },
    onUploadProgress: processCallback,
  });
}

//图片解密
export const imgDecyptApi = async (url) => {
  let encoder = new TextEncoder();
  let KEY = encoder.encode('2019ysapp7527');
  return new Promise((resolve, reject) => {
    imgAxios
      .get(url, {
        responseType: 'arraybuffer',
        timeout: 1000 * 30,
      })
      .then((res) => {
        if (res.status == 200) {
          var arrayBuffer = res.data;
          if (arrayBuffer) {
            var bytes = new Uint8Array(arrayBuffer);
            for (let i = 0; i < 100; i++) {
              bytes[i] ^= KEY[i % KEY.length];
            }
            const blob = new Blob([bytes], {
              type: 'image/png',
            });
            const url = URL.createObjectURL(blob);
            resolve(url);
          }
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

//广告点击
export function adClickReport(data) {
  return axios.post(`ads/click`, data);
}

// 获取域名地址
export function noRedMessage() {
  return axios.get(`msg/dynamic/noRedNum`);
}

// 数据埋点
export function dataAdd(data) {
  let appConfig = eval('(' + sessionStorage.getItem('appConfig') + ')');

  let userInfo = eval('(' + sessionStorage.getItem('userInfo') + ')');

  let domainUrl = '';
  if (appConfig['sourceList']) {
    let objList = appConfig['sourceList'];
    for (let i = 0; i < objList.length; i++) {
      if (objList[i]['type'] == 'DataBuried') {
        domainUrl = objList[i]['domain'][0]['url'];
      }
    }
  }
  let requestData = null;

  //视频数据
  if (data['dataType'] == 1) {
    requestData = {
      dataType: data['dataType'],
      videoDatas: {
        appId: 23,
        videoId: data.videoId,
        videoTitle: data.videoTitle,
        userType: userInfo.vipLevel == 0 ? 2 : 1,
      },
    };
  }
  //标签数据
  if (data['dataType'] == 2) {
    requestData = {
      dataType: data['dataType'],
      tagDatas: {
        appId: 23,
        tagsName: data.tagsName,
        userType: userInfo.vipLevel == 0 ? 2 : 1,
      },
    };
  }

  //vip金币充值数据
  if (data['dataType'] == 3) {
    requestData = {
      dataType: data['dataType'],
      vipGraphicss: {
        appId: 23,
        graphicsId: data.graphicsId,
        userType: userInfo.vipLevel == 0 ? 2 : 1,
        graphicsType: data.graphicsType,
        graphicsTitle: data.graphicsTitle,
      },
    };
  }

  if (!requestData) {
    return;
  }

  return axios.post(`${domainUrl}/api/embed/prd/dataAdd`, requestData);
}
